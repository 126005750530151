<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="quizzes"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="quiz"
    table-css-class="quiz-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="getQuizType(item.type)"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="getLayoutType(item.setting.layout)"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
      <CreatedByAndAtColumn
        :title-header="tableHeaders[5].name"
        :subtitle-header="tableHeaders[5].subHeader"
        :created-at="item.modifiedAt"
        :created-by="item.modifiedBy"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import QuizTypeMixin from '@/components/mixins/valueObject/QuizTypeMixin'
import { AVAILABLE_LAYOUTS } from '@/model/ValueObject/QuizLayout'

export default {
  name: 'QuizTable',
  mixins: [QuizTypeMixin],
  props: {
    quizzes: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('quiz.list.id') },
        { name: this.$t('quiz.list.title') },
        { name: this.$t('quiz.list.type') },
        { name: this.$t('quiz.list.layout') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        {
          name: this.$t('system.modified_at'),
          subHeader: this.$t('system.modified_by')
        },
        { name: this.$t('actions') }
      ]
    }
  },
  methods: {
    getQuizType (quizType) {
      const quizTypeValue = this.quizTypeValues.find(item => item.id === quizType)
      return quizTypeValue?.title
    },
    getLayoutType (layoutType) {
      return AVAILABLE_LAYOUTS.find(item => item.id === layoutType)?.title
    }
  }
}
</script>
<style lang="scss">
  .quiz-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(80px) auto rem(130px) rem(130px) rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(80px) auto rem(130px) rem(130px) rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
